<template>
  <div class="px-4 py-6 md:px-8" :class="[config.main.padded && 'container']">
    <ContentRenderer :key="page._id" :value="page" />
  </div>
</template>

<script setup lang="ts">
const { page } = useContent();
const config = useConfig();

useSeoMeta({
  title: `${page.value?.title ?? '404'} - ${config.value.site.name}`,
  description: page.value?.description,
});
</script>
